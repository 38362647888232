@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,500,600,700|Open+Sans:300,400,600,700,800&display=swap');

$blue: #1c5caa;
$blue2: #094fa3;
$blue3: #1a7ef7 !default;
$blue4: #9acafc;
$blue6: #d0e5f0;
$white: #fff !default;
$blue1: #e8f1f7;
$blue5: #849dbb;
$black: #000 !default;
$black2: #050505;
$gray: #696666;
$gray2: rgba(200, 200, 200, 0.5);
$gray3: #d8d8d8;

body {
  font-size: 16px;
}

.btn-primary {
  background-color: $blue3;
  border-color: $blue3;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

ul {
  padding-left: 20px;
}

.form-control:focus,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

header {
  .btn-outline-primary {
    border-width: 2px;
    color: #1c5caa;
    border-color: #007bff;

    &:hover {
      background-color: #007bff;
      border-color: #007bff;
    }
  }

  .btn-primary {
    color: #ffffff;
  }

  background: linear-gradient(180deg, #d0e5ef 2.7%, #fafafa 98.72%);
  min-height: 67px;
  padding-top: 15px;
  padding-bottom: 15px;

  @media (max-width: 991px) {
    border-bottom: 1px solid #d7e9f2;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.mob_menu_icon_container {
      display: none;
    }

    a:not(.btn) {
      font-size: 18px;
    }
  }

  .logo {
    max-width: 120px;
    margin-right: 35px;

    img {
      width: 100%;
    }
  }

  .btn {
    text-transform: capitalize;
    float: none;
    margin: 0;
    font-size: 15px;
    padding: 8px 16px;
  }

  .city {
    padding: 0 10px 0 0px;
    position: relative;
    margin-left: 50px;

    a {
      font-size: 14px !important;
      color: $blue2;
      vertical-align: middle;
    }

    &:before {
      content: url(../img/iconfinder_location_1814106.svg);
      display: inline-block;
      margin-right: 15px;
      width: 25px;
      vertical-align: middle;
    }
  }

  .tel {
    padding: 0 10px 0 0px;
    position: relative;
    margin-left: 55px;

    @media screen and (max-width: 1200px) {
      margin: 0;
    }

    a {
      color: #094fa3;
      font-size: 14px !important;
      padding: 0 5px;
      border-right: 1px solid #094fa3;

      &:last-of-type {
        border-right: 0;
      }
    }

    &:before {
      content: url(../img/iconfinder_Resume_Phone_2316258.svg);
      display: inline-block;
      margin-right: 0;
      width: 25px;
      vertical-align: middle;
    }
  }

  .user_box {
    padding: 5px 25px;
    margin-top: 7px;

    img {
      display: inline-block;
      vertical-align: middle;
      max-width: 24px;
      margin-right: 10px;
    }

    p {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      color: $blue;
    }
  }

  .footer_nav {
    display: none;
  }

  .mob_box_menu > * {
    float: right;
  }
}

a:hover {
  text-decoration: none;
}

h3 {
  font-size: 24px;
  padding-bottom: 30px;
}

.search_section {
  padding: 0 0 50px;
  text-align: center;
  background: #fafafa;

  &.search_absolute {
    background: transparent;
  }

  .title {
    font-size: 36px;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
  }

  p {
    color: #7f7f7f;
    font-size: 18px;
  }

  .search {
    margin-top: 30px;
    box-sizing: border-box;
    position: relative;
    text-align: left;
    border: 2px solid #1a7ef7;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    .input_search {
      padding-left: 15px;
    }

    input {
      border: none;
      border-radius: 0;
      background: none;
      padding: 5.5px;
      margin: 7px 0;
      outline: none;
      width: 100%;
      font-size: 14px !important;

      @media (max-width: 991px) {
        padding: 5.5px;
      }
    }

    label {
      position: relative;
      width: 23%;
      display: inline-block;
      padding-left: 15px;
      margin: 0;

      &:before {
        position: absolute;
        top: 19px;
        left: 15px;
      }
    }

    .input_search {
      width: 91%;

      .tab_speciality {
        display: flex;
        border-radius: 4px;
        align-items: flex-end;
        background-color: #1b5caa;
        color: #fff;
        font-weight: bold;
        padding: 5.5px 8px;
        margin: 5.5px 0;
        outline: none;
        width: fit-content;
        font-size: 14px;

        @media (max-width: 991px) {
          padding: 12px;
          width: calc(100% - 15px);
          justify-content: space-between;
        }

        .close {
          cursor: pointer;
          font-weight: 500;
          color: #fff;
          margin-left: 5px;
        }
      }

      .input_search_results {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        background: $white;
        box-shadow: 0px 2px 11px rgba(168, 166, 166, 0.5);
        border-radius: 5px;
        display: none;
        z-index: 99;

        @media (max-width: 991px) {
          top: 60px;
        }

        span {
          color: $blue;
        }

        p {
          background: #f1f1f1;
          font-size: 14px;
          color: $gray;
          padding: 10px 15px;
          margin-bottom: 5px;
        }

        ul {
          padding: 0;
          margin: 0;
        }

        li {
          list-style: none;
          padding: 0 0 12px 15px;
        }

        a,
        .search_result_name {
          color: #7f7f7f;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        span:hover {
          text-decoration: underline;
        }
      }
    }

    .input_area:before {
      content: url(../img/iconfinder_location_1814106.svg);
    }

    .input_date {
      input {
        border: none;
      }

      &:before {
        content: url(../img/iconfinder_calendar_1814093.svg);
      }

      input:focus:before {
        fill: red;
      }
    }

    button {
      border-radius: 0;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border: 0;
      background: #1a7ef7;
      padding: 0 30px;

      @media (max-width: 991px) {
        height: 50px;
      }
    }
  }

  .search_form_result {
    background: #ffffff;
    border: 1px solid $gray3;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;

    a {
      display: flex;
    }

    .text {
      width: 100%;
      padding: 15px 55px 5px 15px;
      font-size: 16px;
      color: $black;
      text-align: left;
    }

    .icon {
      padding: 10px;
      width: 40px;
      border-left: 1px solid $gray3;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

.ui-datepicker {
  font-size: 18px;
  text-align: center;
  background: $white;
  box-shadow: 0px 2px 11px rgba(168, 166, 166, 0.5);
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  left: -999999px;

  td,
  th {
    padding: 0 7px;
  }

  th span {
    color: #060606;
    font-weight: 400;
  }
}

.ui-datepicker-title {
  color: $gray;
}

.ui-datepicker-header {
  border-bottom: 1px solid $blue1;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .ui-corner-all {
    position: absolute;
    top: 15px;
    font-size: 0;
    cursor: pointer;
  }
}

.ui-datepicker-prev {
  left: 15px;
  transform: rotate(180deg);

  &:before {
    content: url('../img/CombinedShape.svg');
  }
}

.ui-datepicker-next {
  right: 15px;

  &:before {
    content: url('../img/CombinedShape.svg');
  }
}

.top_specialists {
  text-align: left;
  padding: 0 0 60px 0;

  .top_specialist_box {
    text-align: center;
    max-width: 180px;

    .img_box {
      background: $blue1;
      width: 89px;
      height: 89px;
      line-height: 130px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      vertical-align: sub;
    }

    p {
      font-size: 16px;
      padding-top: 10px;
    }
  }
}

.all_specialties {
  background: $blue1;
  padding: 50px 0;

  ul {
    list-style: none;
    height: 672px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    li {
      page-break-inside: avoid;
      break-inside: avoid;
      position: relative;
      padding-left: 20px;
      width: 25%;

      &::before {
        content: '';
        width: 4px;
        height: 4px;
        background: $black;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
  }
}

footer {
  padding: 30px 0 40px 0;
  font-size: 14px;

  li {
    list-style: none;
  }

  .footer_nav {
    list-style: none;
    height: 130px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    li {
      page-break-inside: avoid;
      break-inside: avoid;
      position: relative;
      width: 50%;
      padding-bottom: 5px;

      &:nth-child(4) {
        min-height: 50px;
      }
    }
  }
}

.social_network {
  text-align: center;
  float: right;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;
    padding: 0 15px;

    a {
      transition: 0.3s;
      opacity: 1;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.subscription {
  display: inline-block;
  vertical-align: top;
  min-width: 50%;

  input {
    background: #efefef;
    border: 1px solid #efefef;
    border-radius: 0.25rem 0 0 0.25rem;
    display: inline-block;
    width: calc(100% - 130px);
    float: left;
    font-size: 14px;
    padding: 10px 20px;
    height: auto;
  }

  .btn {
    background: #bce0f5;
    border-radius: 0 0.25rem 0.25rem 0;
    font-size: 14px;
    padding: 10px 20px;
  }
}

.search_absolute {
  top: 100px;
  margin-top: 10px;
  padding: 10px 0 0;
  width: 100%;

  .search {
    margin: 0;
  }
}

.nav-tabs {
  border: none;

  .nav-link {
    border: none;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0.5rem 0rem;
    margin: 0 25px 0 0;
    color: #8e8e8e;

    &.active {
      color: #1a7ef7;
      border-bottom: 3px solid #1a7ef7;
    }
  }
}

.fade:not(.show) {
  display: none;
}

.tab-content {
  background: $blue1;
  padding: 20px 0 35px 0;
}

.pagination {
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  .prev {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .page-link {
    padding: 0.65rem 0.85rem;
    border-radius: 0.25rem;
    line-height: 1;
    margin: 0 5px;

    img {
      vertical-align: baseline;
    }
  }
}

.page-item.active .page-link {
  background-color: $blue3;
  border-color: $blue3;
}

.doctors_item {
  background: $white;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;

  .short_description {
    padding: 25px;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 300px);

    .img_box {
      width: 130px;
      height: 130px;
      display: inline-block;
      vertical-align: top;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 25px;

      img {
        width: 100%;
      }
    }

    .text {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 160px);
      color: $gray;
    }

    .name {
      font-size: 24px;
      line-height: 31px;
      font-weight: bold;
      color: $black;
      text-decoration: none;
      padding-bottom: 8px;
      margin: 0;

      &:hover {
        text-decoration: none;
      }
    }

    .specialty {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 15px;

      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        color: #1a7ef7;
        border-right: 1px solid $blue1;
        padding-right: 8px;
        padding-bottom: 5px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    .address {
      b {
        color: $black;
      }

      p {
        margin-bottom: 0px;
      }

      a,
      b,
      p {
        padding: 5px 0;
      }

      .metro:before {
        content: '';
        background: #ff4040;
        width: 13px;
        height: 13px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 5px;
        vertical-align: middle;
      }
    }

    .division_name {
      color: #1d1b1b;
      font-weight: bold;
    }

    .hospital_name {
      color: #094fa3;
    }
  }
}

.modal-dialog .header-line {
  background: #d0e5f0;
  color: #d0e5f0;
  margin-right: -40px;
  margin-left: -40px;
}

@media screen and (min-width: 992px) {
  section .working_hours_box.modal {
    display: inline-block;
    opacity: 1;
    z-index: 1;
    overflow: visible;
    position: relative;
    display: inline-block;
    width: 495px;
    border-left: 1px solid $blue1;

    .modal-dialog {
      transform: none;
      margin: 0;
    }

    .modal-content {
      padding: 0;
      border: none;
    }

    .close,
    .modal_title {
      display: none;
    }
  }
}

.search_result .working_hours_box {
  display: inline-block;
  width: 495px;
  border-left: 1px solid $blue1;

  .schedule_wrapper {
    margin-right: 15px;
    display: flex;
  }
}

.schedule-time-list__item-background {
  position: absolute;
  width: 39px;
  height: 66px;
  right: 20px;
  top: -10px;
  background: #e3f6ff;
  border: 1px solid #d0e5f0;
  box-sizing: border-box;
  border-radius: 4px;
  transform: rotate(-90deg);
}

.schedule-time-list__item.more {
  position: absolute;

  ul {
    display: flex;
    width: max-content;
    max-width: 16.75rem;
    padding: 15px 0 0 0;
    flex-wrap: wrap;
    background: $white;
    box-shadow: 0 2px 11px rgba(168, 166, 166, 0.5);
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    opacity: 0;
    z-index: -1;
    transition: 0.3s;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background: $white;
      position: absolute;
      top: -5px;
      left: 50%;
      margin-left: -5px;
      transform: rotate(45deg);
      z-index: -1;
    }

    li a {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  &:hover ul {
    opacity: 1;
    z-index: 1;
  }
}

.working_hours_box {
  & > div {
    display: flex;
  }

  .time_box {
    border-left: 1px solid $blue1;

    &:first-child {
      border: none;
    }

    ul {
      padding: 0;
      margin: 20px 0;
    }

    li {
      list-style: none;

      button {
        font-size: 13px;
        background: $blue3;
        padding: 4px 5px;
        color: $white;
        border-radius: 4px;
        margin: 4px 6px;
        display: block;
        vertical-align: middle;
        height: 28px;
        width: 56px;
        text-align: center;
        border: 0px;

        &.empty {
          background: $blue1;
          font-size: 0;
          line-height: 20px;

          &:before {
            content: '';
            width: 25px;
            height: 2px;
            background: $blue5;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .calendar_time_box {
    border: 1px solid $blue4;
    border-radius: 5px;
    margin-top: 8px;

    .days_box {
      align-items: center;
      justify-content: center;
      border: none;
    }

    .time_box {
      border: none;
      padding: 1px;

      ul {
        margin: 0;
        text-align: center;
      }

      li {
        list-style: none;
        display: inline-block;
        vertical-align: top;

        a {
          font-size: 14px;
          background: $blue3;
          padding: 4px 5px;
          color: $white;
          border-radius: 4px;
          margin: 4px 1px;
          display: block;
          vertical-align: middle;
          height: 28px;
          width: 54px;
          text-align: center;

          &.empty {
            background: $blue1;
            font-size: 0;
            line-height: 20px;

            &:before {
              content: '';
              width: 25px;
              height: 2px;
              background: $blue5;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        &:last-child {
          line-height: 21px;

          a {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.2;
          }
        }
      }
    }
  }
}

.doctor_section .working_hours_box #week .time_box li:first-child a {
  color: $black;
  background: none;
}

.free_time {
  padding: 40px 30px;

  div {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 50px;
    display: block;
    text-align: center;

    p {
      color: $black;
      max-width: 220px;
      margin: 0 auto 12px auto;
    }
  }
}

.search_result .container {
  position: relative;
}

.days_box {
  display: flex;
  align-items: center;
  position: absolute;
  top: -67px;
  right: 5px;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  .days_items {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .calendar_ico {
    margin-right: 10px;
    cursor: pointer;
  }

  .flatpickr-input {
    opacity: 0;
    width: 0;
    visibility: hidden;
  }

  @media (max-width: 991px) {
    top: -170px;
    width: 100%;
  }

  .title {
    color: $gray;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: 0 15px 0 0;

    @media (max-width: 768px) {
      display: block !important;
    }
  }

  .mob_date {
    display: none;
  }

  ul {
    background: #e3f6ff;
    border-radius: 4px;
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    display: inline-block;
    border-right: 1px solid $blue1;
    float: left;

    &:last-child {
      border: none;
    }

    a {
      margin: 4px 6px;
      min-height: 28px;
      padding: 9px 2px;
      font-size: 14px;
      color: #000;
      display: block;
      width: 56px;
      text-align: center;
    }
  }

  .next:before {
    content: url('../img/arr.svg');
    margin: 0 8px;
    cursor: pointer;
  }

  .prev {
    transform: rotate(180deg);

    &:before {
      content: url('../img/arr.svg');
      cursor: pointer;
      margin: 0 8px;
    }
  }
}

.hospitals_item {
  background: $white;
  border-radius: 5px;
  margin-bottom: 20px;

  .short_description {
    padding: 25px;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 360px);

    .img_box {
      width: 130px;
      height: 130px;
      display: inline-block;
      vertical-align: top;
      margin-right: 25px;

      img {
        width: 100%;
      }
    }

    .text {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 160px);
    }

    .name {
      font-weight: bold;
      font-size: 24px;
      color: $black;

      margin-bottom: 15px;
      line-height: 1.2;

      a {
        color: $black;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }

    .address {
      .time {
        color: $gray;

        &:before {
          content: url('../img/iconfinder_10_171505.svg');
          display: inline-block;
          margin-right: 15px;
          width: 25px;
          vertical-align: middle;
        }
      }
    }
  }

  .main_specialties {
    display: inline-block;
    vertical-align: top;
    width: 350px;
    border-left: 1px solid $blue1;
    padding: 35px;

    ul {
      margin: 0;
      padding: 0 0 0 20px;
    }
  }
}

.modal_back {
  display: none;
}
.text-left {
  text-align: left;
}

.city-header-dropdown button, .city-header-dropdown button:hover, .city-header-dropdown button:focus {
  background: none;
  border: 0;
  color: #1a7ef7;
}

.span-flex {
  display: flex;
  margin-left: auto;
}

.modal_title {
  font-weight: bold;
  font-size: 24px;
  color: $black;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
  padding: 0 25px 1rem 0;
}

#modal .modal_title {
  padding: 0 15px 0 0;
  border: none;
}

#not_available ul {
  padding-left: 20px;
}

.appointment-form-btn-wrapper {
  display: flex;
  flex-direction: column;

  button {
    margin: 10px 10px 0;
  }
}

.modal-content {
  padding: 30px 40px;

  .close {
    position: absolute;
    top: 20px;
    right: 25px;
    outline: none;

    span {
      font-size: 3rem;
      font-weight: 100;
    }
  }

  form {
    p {
      font-weight: bold;
      font-size: 18px;
    }

    .small {
      font-weight: normal;
      font-size: 80%;
    }

    .error_text {
      color: #ff7575;
      font-size: 14px;
      float: right;
      max-width: calc(100% - 220px);
      opacity: 0;
      transition: 0.3s;
    }

    input {
      background: $white;
      border: 1px solid #7f7f7f;
      border-radius: 4px;
      padding: 10px 15px;
      margin-bottom: 1rem;
      transition: 0.3s;
    }

    .error {
      input {
        border: 1px solid #ff4949;
      }

      .error_text {
        opacity: 1;
      }
    }
  }

  .sms input {
    width: 43px;
    margin-right: 10px;
    display: inline-block;
  }

  .confirmed {
    & > div {
      border: 1px solid #7f7f7f;
      border-radius: 4px;
      margin-bottom: 1rem;
      overflow: hidden;
    }

    label {
      border-bottom: 1px solid #7f7f7f;
      width: 100%;
      margin: 0;

      &:last-child {
        border: none;
      }
    }
  }
}

.appointment-modal {
  @media screen and (min-width: 576px) {
    min-width: 600px;
  }
  .modal-content {
    padding: 25px;
  }

  .submit-button {
    width: 100%;
    height: 50px;
  }

  .appointment-auth-text,
  .appointment-confirmation-text {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
    color: #333333;
  }

  .appointment-confirmation-text {
    font-size: 15px;
    font-weight: 400;

    .time {
      font-weight: bold;
    }
  }
}

.modal {
  .info_box.appointment {
    span {
      min-width: 180px;
      margin-right: 50px;
      color: $gray;
      display: inline-block;
    }

    p {
      color: #333;
      display: inline-block;
    }

    div {
      display: flex;
    }

    .reception_time {
      border-bottom: 1px solid #d0e5f0;
      margin-bottom: 1rem;

      p {
        font-weight: bold;
        color: $blue3;
      }
    }
  }
}

.warning {
  background: #fff0db;
  border: 1px solid #f6c38b;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;
  color: #dc8d29;
  margin-bottom: 1rem;
}

.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

.checkbox__text {
  position: relative;
  padding: 0 0 0 40px;
  cursor: pointer;
  font-size: 14px;
  color: #8e8e8e;
}

.checkbox__text:before {
  content: url(../img/check.svg);
  position: absolute;
  top: 2px;
  left: 6px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  z-index: 2;
}

.checkbox__text:after {
  content: '';
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: $white;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  transition: 0.2s;
}

.checkbox input:checked + .checkbox__text:before {
  opacity: 1;
}

.radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 7px;
}

.radio__text {
  color: #5b5b5b;
  position: relative;
  padding: 15px 5px 15px 60px;
  cursor: pointer;
  transition: 0.2s;
}

.radio__text:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 15px;
  width: 30px;
  height: 30px;
  border: 1px solid #7f7f7f;
  border-radius: 50%;
  background: #fff;
}

.radio__text:after {
  content: '';
  position: absolute;
  top: 12px;
  left: 15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  border: 8px solid $blue3;
  opacity: 0;
  transition: 0.2s;
}

.radio input:checked + .radio__text:after {
  opacity: 1;
}

.radio input:checked + .radio__text {
  background: #e7f6ff;
}

.doctor_page {
  .time_slots {
    @media screen and (max-width: 991px) {
      padding: 0 !important;
      overflow-x: initial !important;

      ul {
        flex-wrap: wrap;
      }
    }
  }

  .btn-primary.mob {
    display: none;
  }

  .tab-content {
    padding: 0;
    position: relative;
    background: none;
  }

  .working_hours_box {
    .days_box {
      position: static;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid $blue4;
    }
  }

  .working_hours_box {
    border: none;
  }

  .short_description {
    padding: 0;
  }

  .doctor_section {
    background: $blue1;
    padding: 25px 0;

    .doctors_item {
      padding: 25px;
    }
  }

  .doctors_item > div,
  .tab-pane .wrapp {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .week {
    border: 1px solid $blue4;
    border-radius: 0 0 5px 5px;
  }
}

.main_info {
  p,
  span,
  li {
    color: $gray;
  }

  h3 {
    font-weight: 700;
    padding: 10px 0 20px 0;
  }

  b {
    color: $black2;
    font-size: 18px;
  }

  .address {
    padding: 20px 0;
  }
}

.doctors_item .short_description .main_info .address p {
  padding: 7px 0;
}

.tab-content,
.tab-pane {
  width: 100%;
}

.nav_tabs_box {
  padding: 20px;
  background: $blue3;
  display: flex;
  border-radius: 5px 5px 0 0;
  justify-content: center;

  h3 {
    padding: 0;
    margin: 0;
    color: $white;
  }

  .nav-tabs {
    border: 1px solid $blue1;
    border-radius: 4px;

    .nav-link {
      font-size: 14px;
      margin: 0;
      padding: 7px;
      color: $white;
      min-width: 82px;
      text-align: center;

      &:hover,
      &.active {
        color: $blue;
        border: none;
        background: $blue4;
      }
    }
  }
}

.calendar_box {
  display: flex;
  border: 1px solid $blue4;
  border-radius: 0 0 5px 5px;
  overflow: hidden;

  * {
    font-size: 14px;
  }

  .ui-icon {
    font-size: 0;
  }

  .ui-datepicker-month {
    text-transform: uppercase;
  }

  .ui-datepicker {
    box-shadow: none;
    border-radius: 0;
    width: 50%;
    padding: 0;
    position: static;

    &:first-child {
      .ui-datepicker-calendar_box {
        border-right: 1px solid $blue4;
      }

      .ui-datepicker-next {
        display: none;
      }
    }

    &:last-child {
      .ui-datepicker-prev {
        display: none;
      }
    }

    .ui-datepicker-calendar_box {
      padding: 10px 0;
    }
  }

  .ui-datepicker th {
    padding: 0 7px 7px 7px;
  }

  .ui-datepicker-calendar {
    margin: 0 auto;

    td {
      padding: 1px;
      cursor: pointer;

      &.ui-datepicker-week-end {
        cursor: default;
      }

      &.unavailable {
        cursor: default;
      }
    }

    a,
    span {
      border-radius: 5px;
      display: inline-block;
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      background: rgba(166, 199, 255, 0.5);
      border: 1px solid $blue3;
      color: #0b7ed2;
    }

    .no_reception a,
    .no_reception span {
      color: #7f7f7f;
      border: 1px solid #d6d6d6;
      background: #fff;
    }

    .unavailable a,
    .unavailable span {
      background: $gray2;
      border: 1px solid $gray2;
    }
  }

  .ui-datepicker-header {
    position: relative;
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid $blue4;

    .ui-corner-all {
      top: 13px;
    }
  }
}

.hospital_section {
  background: $blue1;
  padding: 20px 0 35px 0;

  .container {
    background: #fff;
    border-radius: 5px;
  }

  .doctors_item {
    .short_description {
      width: 100%;
      border-bottom: 1px solid $blue1;
      padding: 20px;

      .img_box {
        width: 100px;
        height: 100px;
      }

      .text {
        width: calc(100% - 140px);
      }
    }

    .name {
      font-size: 20px;
    }
  }

  .hospitals_item {
    border-bottom: 1px solid $blue1;
    padding: 30px;

    .img_box {
      width: 130px;
      height: 130px;
      display: inline-block;
      vertical-align: top;
      margin-right: 25px;

      img {
        width: 100%;
      }
    }

    .text {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 160px);

      .name {
        font-weight: bold;
        font-size: 24px;
        color: #000;
        margin-bottom: 15px;
        line-height: 1.2;
      }
    }

    .box {
      display: flex;
      justify-content: space-between;

      div {
        position: relative;
      }

      .tel {
        a,
        span {
          display: block;
          color: #094fa3;
        }

        &:before {
          content: url(../img/iconfinder_Resume_Phone_2316258.svg);
          display: inline-block;
          margin-right: 15px;
          width: 25px;
          vertical-align: middle;
        }
      }

      .address {
        &:before {
          content: url(../img/iconfinder_location_1814106.svg);
          display: inline-block;
          margin-right: 15px;
          width: 25px;
          vertical-align: middle;
        }
      }

      .time:before {
        content: url(../img/iconfinder_10_171505.svg);
        display: inline-block;
        margin-right: 15px;
        width: 25px;
        vertical-align: middle;
      }
    }
  }

  .description {
    h3 {
      font-weight: 700;
    }

    p {
      color: $gray;
    }

    b {
      font-size: 18px;
      color: $black2;
    }

    .right {
      ul {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 390px;
      }

      li {
        width: 50%;
      }
    }

    select {
      background: #ffffff;
      border: 1px solid #7f7f7f;
      box-sizing: border-box;
      border-radius: 4px;
      min-width: 270px;
      padding: 8px;
      margin-left: 20px;
    }

    .directions_form {
      padding: 0 0 20px 30px;
    }

    .tab-content > .tab-pane {
      display: block;
      opacity: 1;
    }

    .tab-content {
      margin: 0;
      background: none;
    }

    .nav-tabs {
      display: none;
    }
  }
}

.back_btn {
  font-size: 14px;
  line-height: 18px;
  color: #1c5caa;
  position: relative;
  bottom: 15px;
  padding-left: 20px;

  &:before {
    content: url(../img/arr_blue.svg);
    position: absolute;
    top: 2px;
    left: 0px;
  }
}

.mob_box_menu {
  display: none;
}

.desktop_box_menu,
.mob_box_menu {
  .office_link {
    display: flex;
    margin-top: 7px;
    margin-right: 30px;

    a {
      font-size: 14px !important;
      line-height: 18px;
      color: #1c5caa;
    }

    img {
      width: 25px;
      margin-right: 5px;
    }
  }
}

.desktop_box_menu {
  width: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right_box {
    display: flex;
    align-items: center;

    .btn {
      margin-right: 10px;
    }
  }

  @media screen and (max-width: 991px) {
    justify-content: flex-end;
    .tel {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .user_box {
      border-color: $blue4;
      border-style: solid;
      border-width: 1px 0 1px 0;
      padding: 15px 0px;
    }

    .tel {
      padding: 0;

      a {
        display: block;
      }

      &:before {
        display: none;
      }
    }

    .footer_nav {
      display: block;
    }
  }
}

@media screen and (max-width: 1200px) {
  .top_specialists {
    .top_specialist_box {
      max-width: 130px;

      .img_box {
        width: 89px;
        height: 89px;
        line-height: 100px;
      }

      p {
        font-size: 14px;
      }

      img {
        max-height: 40%;
      }
    }
  }

  footer .footer_nav {
    height: 145px;
  }

  .social_network {
    text-align: left;
    float: none;
    margin-top: 15px;
  }

  .all_specialties ul {
    height: 1000px;
  }

  .all_specialties ul li {
    width: 33%;
  }

  .search_section .search input {
    font-size: 16px;
  }

  header {
    .container {
      justify-content: left;

      &.mob_menu_icon_container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: block;
      }

      .mob_menu_icon {
        display: inline-block;
        width: 22px;
        height: 14px;
        position: absolute;
        top: 40px;
        right: 20px;
        z-index: 9;
        cursor: pointer;

        &:before {
          content: '';
          width: 100%;
          height: 14px;
          display: block;
          border-top: 2px solid $black2;
          border-bottom: 2px solid $black2;
          position: relative;
          transform: rotate(0deg);
          transition: 0.3s;
        }

        &:after {
          content: '';
          width: 100%;
          display: block;
          border-bottom: 2px solid $black2;
          position: absolute;
          top: 6px;
          left: 0;
          transform: rotate(0deg);
          transition: 0.3s;
        }

        &.active:before {
          top: 8px;
          left: 5px;
          border-bottom: 2px solid rgba(255, 255, 255, 0);
          transform: rotate(-45deg);
        }

        &.active:after {
          transform: rotate(45deg);
          top: 10px;
          left: 1px;
        }
      }
    }
  }

  header .mob_box_menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $white;
    z-index: 1;
    padding: 0px 20px 0px 20px;
    max-height: 0;
    overflow: hidden;
    transition: 0.4s;

    & > * {
      float: none;
      width: 100%;
      margin-bottom: 25px;
    }

    .user_box {
      border-color: $blue4;
      border-style: solid;
      border-width: 1px 0 1px 0;
      padding: 15px 0px;
    }

    .tel {
      padding: 0;

      a {
        display: block;
      }

      &:before {
        display: none;
      }
    }

    .footer_nav {
      display: block;
    }
  }

  header.active {
    .mob_box_menu {
      max-height: 999px;
      padding: 70px 20px 20px 20px;
      min-height: 100vh;
    }

    .mob_menu_icon_container {
      max-width: 100%;
    }

    .mob_menu_icon {
      top: 25px;
    }
  }

  .search_section.search_absolute {
    position: static;
  }
}

@media screen and (max-width: 991px) {
  .doctors_item {
    display: block;
  }

  .hospitals_item {
    display: block;
  }

  .search_section {
    padding: 20px 0 100px 0;
  }

  .top_specialists {
    padding: 50px 0;
  }

  .search_section .search .input_search,
  .search_section .search label {
    width: 100%;
  }

  .search_section .search button {
    position: relative;
    height: inherit;
  }

  .search_section .search .input_search {
    padding-left: 0;
  }

  .search_section .search input {
    border: none;
  }

  .hospitals_item .main_specialties,
  .hospitals_item .short_description,
  .doctors_item .short_description {
    width: 100%;
  }

  .hospitals_item .main_specialties {
    padding-top: 0;

    p {
      font-size: 18px;
    }
  }

  .hospital_section .description .directions_form {
    padding-left: 0;
  }

  .search_result .working_hours_box {
    display: block;
    margin: 0 auto;
    border-left: none;
  }

  header .container {
    justify-content: left;
  }

  .hospital_section {
    .hospitals_item {
      padding: 30px 0 0 0;
      border: none;
      margin: 0;

      .text {
        width: 100%;

        .name {
          font-size: 24px;
          text-align: center;
        }
      }

      .img_box {
        width: 140px;
        height: 140px;
        display: block;
        margin: 0 auto 20px auto;
      }

      .box {
        display: block;

        div {
          margin-bottom: 20px;
        }
      }
    }

    .description {
      .tab-content > .tab-pane {
        display: block;
        opacity: 1;
        padding: 0;
      }

      .tab-content {
        margin: 0;
        background: none;
      }

      .nav-tabs {
        display: flex;
        border-bottom: 2px solid $blue1;

        .nav-link {
          margin: 0 25px -2px 0;
        }
      }
    }
  }

  .doctor_page {
    .btn-primary.mob {
      padding: 10px 20px;
      width: 100%;
      display: block;
    }

    .tab-pane .wrapp {
      justify-content: center;
    }

    .days_box {
      margin-bottom: 0;
    }

    .working_hours_box {
      .week {
        border: none;
      }

      .modal-dialog {
        max-width: 300px;
        margin: 20px auto;

        .modal-content {
          padding: 0;
        }
      }

      .time_box {
        display: none;

        &.mob_visible {
          display: block;
        }

        ul {
          margin-top: 0;
        }

        .days_box .title {
          display: block;
        }
      }

      .nav_tabs_box h3 {
        display: none;
      }
    }

    .modal_back {
      display: block;
      position: absolute;
      top: 25px;
      left: 15px;
    }

    .modal_title {
      font-size: 18px;
      margin: 25px 30px;
      padding: 0;
      border: none;
      text-align: center;
    }

    .modal-content .close {
      top: 10px;
      right: 10px;
    }

    .calendar_box {
      border: none;

      .ui-datepicker {
        width: 100%;

        &:last-child {
          display: none;
        }

        &:first-child .ui-datepicker-next {
          display: block;
        }
      }
    }

    .calendar_time_box {
      border: none;
      border-radius: 0;

      .days_box {
        border-color: $blue6;
        border-width: 1px 0 1px 0;
        border-style: solid;
      }

      .time_box {
        display: block;

        ul {
          text-align: center;
          padding: 10px 0;
        }

        li a {
          margin: 4px 2px;
        }
      }
    }

    .nav_tabs_box {
      justify-content: center;
    }
  }

  .search_section {
    padding: 20px 0 0 0;
  }
}

@media screen and (max-width: 991px) {
  .doctor_page {
    .doctors_days_box {
      li {
        width: 25%;
        display: none;
        text-align: center;
        border: 1px solid $blue6;

        a {
          width: auto;
          display: inline-block;
          margin: 0;
        }
      }

      .day_of_the_week,
      .mob_date {
        display: block;
        font-size: 12px;
      }

      .desk_date {
        display: none;
      }

      .mob_visible {
        display: inline-block;

        &:first-child {
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &.active {
          background: #1a7ef7;

          a,
          span {
            cursor: pointer;
            color: #fff;
          }
        }
      }

      ul {
        width: calc(100% - 50px);
        display: table;
        border: 1px solid $blue6;
      }
    }
  }

  .search_result .working_hours_box {
    max-width: 100%;
    width: 100%;
    padding: 0 20px 20px 20px;
    margin: 0;
    overflow-x: scroll;

    & > div {
      display: block;
    }

    .time_box {
      display: none;

      &:first-child {
        display: block;
      }

      ul {
        display: flex;

        .more {
          display: flex;

          & > a {
            display: none;
          }

          ul {
            position: static;
            opacity: 1;
            transform: translateX(0%);
            z-index: 1;
            margin: 0;
            background: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .search_page .days_box {
    li {
      width: 25%;
      display: none;
      text-align: center;
      border: 1px solid $blue6;

      a {
        width: auto;
        display: inline-block;
        margin: 0;
      }
    }

    .day_of_the_week,
    .mob_date {
      display: block;
      font-size: 12px;
    }

    .desk_date {
      display: none;
    }

    .mob_visible {
      display: inline-block;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &.active {
        background: #1a7ef7;

        a,
        span {
          cursor: pointer;
          color: #fff;
        }
      }
    }

    ul {
      width: calc(100% - 50px);
      display: table;
      border: 1px solid $blue6;
    }
  }
}

.pagination_bootstrap {
  .page-item {
    z-index: 0;
  }
}

@media screen and (max-width: 991px) {
  .search {
    display: flex;

    .fields_wrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .search_section {
    margin-top: 0;
    padding-top: 15px;

    .title {
      font-size: 32px;
    }
  }

  .all_specialties ul {
    height: 1550px;
  }

  .all_specialties ul li {
    width: 50%;

    a {
      font-size: 14px;
    }
  }

  footer .footer_nav {
    height: 153px;
  }

  .modal-content form .error_text {
    float: none;
    max-width: 100%;
    display: block;
  }

  .hospital_section .doctors_item,
  .doctors_item,
  .hospitals_item {
    .short_description {
      padding: 20px 15px 20px 20px;

      .img_box {
        width: 100px;
        height: 100px;
        margin-right: 10px;
      }

      .text {
        width: calc(100% - 120px);
      }

      .name {
        font-size: 20px;
      }
    }
  }

  .hospital_section .description .directions_form {
    padding-left: 0;

    select {
      min-width: 1px;
      margin-left: 0;
      display: block;
      width: 100%;
    }
  }

  .search_page .days_box {
    .title {
      display: none;
    }

    li {
      width: 25%;
      display: none;
      text-align: center;
      border: 1px solid $blue6;

      a {
        width: auto;
        display: inline-block;
        margin: 0;
      }
    }

    .day_of_the_week,
    .mob_date {
      display: block;
      font-size: 12px;
    }

    .desk_date {
      display: none;
    }

    .mob_visible {
      display: inline-block;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }

      &.active {
        background: #1a7ef7;

        a,
        span {
          cursor: pointer;
          color: #fff;
        }
      }
    }

    ul {
      width: calc(100% - 50px);
      display: table;
      border: 1px solid $blue6;
    }
  }

  .doctor_page {
    .doctor_section .container {
      padding: 0;
    }

    .doctors_item {
      padding: 25px 15px;

      .short_description {
        padding: 0;

        .img_box {
          width: 140px;
          height: 140px;
          margin: 5px auto 30px;
          display: block;

          @media (max-width: 991px) {
            width: 120px;
            height: 120px;
          }
        }

        .text {
          width: 100%;
        }

        .address {
          padding-top: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  body {
    font-size: 14px;
  }

  .search_result .tab-content .container {
    padding: 0;
  }

  .hospital_section .doctors_item,
  .hospitals_item,
  .doctors_item {
    border-radius: 0;

    .short_description {
      .img_box {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }

      .text {
        width: calc(100% - 75px);
      }

      .name {
        font-size: 18px;
      }
    }
  }

  .modal .info_box p {
    display: block;
  }

  .all_specialties ul li:before {
    display: none;
  }

  .search_section .title {
    font-size: 28px;
  }

  .search_section p {
    font-size: 16px;
  }

  h3 {
    font-size: 20px;
  }

  .modal-content {
    padding: 30px 20px;
  }

  header .container a:not(.btn) {
    font-size: 16px;
  }

  .subscription {
    margin-top: 15px;
  }

  .modal-content .btn {
    width: 100%;
  }

  header {
    .container {
      .mob_menu_icon {
        top: 30px;
      }
    }

    .logo {
      max-width: 100px;
      width: 100%;
      margin-right: 0;

      img {
        width: 100%;
      }
    }
  }
}

.info_box.appointment {
  .address {
    flex-direction: row;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  div {
    display: flex;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}

#appointment {
  @media (min-width: 576px) {
    max-width: 600px;
  }

  .confirmation_of_entry__patients-loading {
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: #979797;
    text-align: center;
    padding: 10px 0;
  }
}

.schedule_placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-left: 1px solid #e8f1f7;
  max-width: 502px;

  @media screen and (max-width: 991px) {
    max-width: inherit;
  }

  .inner_wrapper {
    padding: 20px;
    background: #f1f1f1;
    height: calc(100% - 50px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: calc(100% - 55px);

    @media screen and (max-width: 767px) {
      height: initial;
    }

    p {
      font-weight: bold;
      color: #696666;
      line-height: 20px;
      font-size: 14px;
      margin: 0;
    }

    .link_button {
      cursor: pointer;
      margin-top: 10px;
      background: #187ef7;
      color: #fff;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      padding: 10px;
    }
  }
}

.about_page {
  width: 100%;
  max-width: 690px;
  margin: 0 auto 70px;

  @media (max-width: 1200px) {
    padding: 50px 0px;
  }

  a {
    color: #696666;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: #696666;
  }

  .link_to_home {
    background: #1a7ef7;
    border-radius: 4px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    margin-top: 50px;
    padding: 12px 0;
    justify-content: center;
    display: flex;
    width: 280px;
    line-height: 18px;
    cursor: pointer;
  }

  p {
    color: #555;
    font-size: 15px;
    line-height: 28px;
  }

  .title {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    color: #1d1b1b;
  }
}

.selector.container {
  background: transparent;
}

.division {
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  color: #1d1b1b;

  &.container {
    padding: 15px 15px 15px 60px;
    background: #f8f8f8;
    border-radius: 4px;

    @media (max-width: 991px) {
      padding: 15px;
    }
  }
}

.division_select {
  flex-direction: column;

  .division_title {
    font-size: 12px;
    line-height: 16px;
    padding: 0;
    color: #696666;
  }

  .division_dropdown {
    button {
      white-space: pre-wrap;
      text-align: left;
      background: #f8f8f8 !important;
      border: 0 !important;
      box-shadow: none !important;
      color: #000 !important;
      padding: 0;
      font-weight: bold;
    }

    .dropdown-menu {
      max-height: 50vh;
      overflow-y: auto;

      a {
        white-space: initial;
      }
    }
  }
}

.selector {
  margin: 30px 0 15px 0;
  display: flex;

  @media (max-width: 991px) {
    justify-content: center;
  }

  &.container {
    background: transparent;
  }

  .block_title {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #1d1b1b;
  }

  .directions_form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .block_title {
      margin-right: 15px;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: baseline;
      width: auto;

      .block_title {
        margin-right: 0;
        margin-bottom: 15px;
        font-size: 20px;
      }
    }
  }

  .select {
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(9, 79, 163, 0.1);
    border-radius: 5px;
  }
}

.hospital_doctor_stars {
  .rating {
    p {
      color: #d8d8d8;
    }

    span {
      font-size: 18px;
    }
  }

  .stars_wrapper {
    width: fit-content;
    padding: 5px 15px;
    margin-top: 15px;

    .stars {
      margin-right: 15px;

      .star {
        padding: 0 5px;

        img {
          width: 17px;
        }
      }
    }
  }
}

a {
  color: #007bff;

  &:hover {
    color: #0056b3;
  }
}

.page-link {
  color: #007bff;

  &:hover {
    color: #0056b3;
  }
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;

  &:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
}

.react-select-container {
  .react-select__control {
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .react-select__menu {
    margin-top: 0;
    width: calc(100% + 45px);
    left: -45px;
  }

  .react-select__indicator:last-of-type {
    display: none;
  }
}

.input_area {
  &::before {
    top: 17px !important;
  }
}

.page-item {
  &.disabled {
    cursor: not-allowed;
  }
}

.wrapp .time_box {
  li:first-child {
    span {
      background: none;
      color: #000;
    }
  }
}

.time_box {
  list-style: none;

  span {
    font-size: 14px;
    background: $blue3;
    padding: 4px 5px;
    color: $white;
    border-radius: 4px;
    margin: 4px 6px;
    display: block;
    vertical-align: middle;
    height: 28px;
    width: 56px;
    text-align: center;

    &.empty {
      background: $blue1;
      font-size: 0;
      line-height: 20px;
      cursor: default;

      &:before {
        content: '';
        width: 25px;
        height: 2px;
        background: $blue5;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  li .more {
    span:first-of-type {
      font-size: 18px;
      font-weight: 700;
      line-height: 11px;
    }
  }
}

.days_box {
  span {
    margin: 4px 6px;
    min-height: 28px;
    padding: 9px 7px;
    font-size: 14px;
    white-space: nowrap;
    color: $black;
    display: block;
    width: 56px;
  }
}

.loader.mobile {
  text-align: center;
}

.auth .modal-content {
  form {
    .error {
      padding: 10px 0;
      display: flex;

      input {
        margin: 0;
        border: 1px solid #ff4949;
      }

      .error_text {
        padding-left: 15px;
        opacity: 1;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        input {
          margin: 8px 0;
        }

        .error_text {
          padding: 8px 0;
        }

        .error_text {
          padding-left: 0;
        }
      }
    }
  }

  div {
    p {
      font-weight: bold;
      font-size: 18px;
    }

    .error_text {
      color: #ff7575;
      font-size: 14px;
      float: right;
      max-width: calc(100% - 220px);
      opacity: 0;
      transition: 0.3s;
    }

    input {
      background: #ffffff;
      border: 1px solid #7f7f7f;
      border-radius: 4px;
      padding: 10px 15px;
      margin-bottom: 1rem;
      transition: 0.3s;
    }

    .error {
      display: flex;
      align-items: baseline;

      input {
        border: 1px solid #ff4949;
      }

      .error_text {
        padding-left: 15px;
        opacity: 1;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .error_text {
          padding-left: 0;
          padding-top: 15px;
        }
      }
    }
  }
}

.hospital_description {
  display: flex;
  cursor: default;
  padding: 10px 0;
  font-size: 14px;
  line-height: 24px;
  color: #696666;

  p {
    margin: 0;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #1a7ef7;
    padding-left: 3px;
  }
}

.address {
  display: flex;
  position: relative;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  flex-direction: column;
  padding-bottom: 15px;

  @media screen and (max-width: 991px) {
    padding-top: 15px;
    padding-bottom: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  a {
    font-weight: 500;
  }

  &:hover {
    color: #000;
    text-decoration: none;
  }

  .address_text {
    font-weight: 500;
    font-size: 14px;
    position: relative;
    line-height: 18px;
    color: #696666 !important;

    &:hover {
      text-decoration: none;
    }
  }

  .time {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #1d1b1b;
  }
}

.auth_code_wrapper {
  display: flex;
  align-items: baseline;

  .error_text {
    padding-left: 5px;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;

    .error_text {
      padding-left: 0;
      padding-bottom: 10px;
    }
  }
}

.no_data {
  font-size: 14px;
  line-height: 18px;
  color: #696666 !important;
  font-weight: 400;
}

.speciality {
  padding-bottom: 15px;

  p {
    padding-bottom: 5px;
    margin: 0;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  span {
    font-weight: bold;
    color: #1a7ef7;
    border-right: 1px solid #e8f1f7;
    padding-right: 8px;
    &:last-of-type {
      border-right: 0;
    }
  }
}

.appointment {
  .address {
    padding: 0;

    span {
      font-weight: 500;
    }

    p {
      color: #212529;
      font-weight: 400;
    }
  }

  span {
    min-width: 180px;
    margin-right: 50px;
    color: $gray;
    display: inline-block;
  }
}

.short_appointment {
  .success_message {
    span {
      color: #555;
      margin-left: 10px;
      font-weight: bold;
    }
  }
}

.modal-content {
  .info_box p {
    display: block;
  }

  .info_box {
    .user_info_box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      div {
        display: flex;
        align-items: baseline;

        span {
          margin: 0;
        }
      }

      @media (max-width: 480px) {
        div {
          flex-direction: column;
          width: 100%;

          input {
            width: 100%;
          }
        }
      }
    }

    span {
      margin-right: 50px;
      color: $gray;
      display: inline-block;
    }

    p {
      display: inline-block;
    }

    .reception_time {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 1rem;

      p {
        color: $blue3;
      }
    }
  }
}

.appointment {
  .operator-code {
    border-top: 1px solid #eaeaea;
    margin-bottom: 1rem;
  }
}

#working_hours_box {
  .schedule_wrapper {
    display: flex;
  }

  .schedule_placeholder {
    .inner_wrapper {
      padding: 50px;
    }
  }
}

#appointment .modal_title {
  border: 0;
}

.modal-content form .error {
  .error_text {
    max-width: 100%;
  }
}

.hospitals_item,
.hospital_section {
  .medicament {
    margin-bottom: 10px;
    border: 2px solid #1a7ef7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 10px 15px;

    img {
      cursor: default;
      width: 15px;
      margin-right: 5px;
    }

    span,
    a {
      text-decoration: none;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #1a7ef7;
    }
  }
}

.hospitals_item {
  display: flex;

  .header_box {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .rating_wrapper {
      display: flex;
      flex-direction: column;
      min-width: 300px;
      justify-content: space-between;

      .medicament {
        align-self: flex-end;
      }
    }
  }

  .short_description {
    width: calc(100% - 300px);

    .institution_name {
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #094fa3;

      a {
        color: #094fa3;
        text-decoration-line: underline;
      }
    }

    .address {
      p {
        color: #1c5caa;
      }

      span {
        color: #1c5caa;
      }

      a {
        font-size: 14px;
        line-height: 18px;
        color: #094fa3;
        font-weight: 500;
      }
    }
  }

  .hospital_data {
    max-width: 450px;
  }

  @media (max-width: 991px) {
    flex-direction: column;

    .short_description {
      width: 100%;
    }
  }
}

.links_box:hover .name {
  color: #1a7ef7 !important;
  text-decoration: underline;
}

.container {
  .footer_logo {
    img {
      width: 75px;
      margin-left: 10px;

      @media (max-width: 580px) {
        margin-left: 0;
      }
    }
  }
}

.likari_in_ua {
  padding: 15px 10px 0 10px;
  margin: 0;

  @media screen and (max-width: 991px) {
    text-align: center;
    order: 3;
  }
}

.layout_footer {
  padding: 20px 0;
  position: relative;
  bottom: 0;
  width: 100%;
  background: #fff;

  @media screen and (max-width: 768px) {
    background: #f8f8f8;
    border-top: 1px solid #d7e9f2;
  }

  .footer_info_wrapper {
    display: flex;
    width: 100%;
    align-items: center;

    @media (max-width: 767px) {
      align-items: initial;
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .footer_info_container {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: baseline;
    }

    .tel,
    .email {
      padding: 0 10px 0 0;
      position: relative;

      @media screen and (max-width: 1200px) {
        margin: 0;
      }

      a {
        color: #094fa3;
        font-size: 14px !important;
        padding: 0 5px;
        border-right: 1px solid #094fa3;

        &:last-of-type {
          border-right: 0;
        }
      }

      &:before {
        content: url(../img/phone-alt-solid.svg);
        display: inline-block;
        margin-right: 0;
        width: 17px;
        vertical-align: middle;
      }

      @media screen and (max-width: 520px) {
        margin-left: 25px;
        display: flex;
        flex-direction: column;

        a {
          border-right: 0;
        }

        &:before {
          position: absolute;
          left: -25px;
        }
      }
    }

    .tel {
      margin-bottom: 4px;
    }

    .email {
      &:before {
        content: url(../img/envelope-solid.svg);
        display: inline-block;
        margin-right: 0;
        width: 17px;
        vertical-align: middle;
      }
    }

    .about {
      padding: 10px;

      img {
        margin-right: 5px;
      }

      a {
        white-space: nowrap;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 18px;
        color: #1c5caa;
        display: flex;
        position: relative;
      }
    }

    .city_dropdown {
      padding: 15px 25px 15px 0;

      @media (max-width: 767px) {
        padding: 15px 0;
        width: 100%;
      }

      .city_select {
        width: 208px;

        @media (max-width: 767px) {
          width: 100%;
        }

        button {
          height: 42px;
          width: 100%;
          text-align: left;

          &:after {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .about_wrapper {
      display: flex;
      align-items: center;
      padding: 10px 0;

      @media screen and (max-width: 768px) {
        align-items: baseline;
        .about {
          order: 2;
        }
      }
    }
  }

  .partners_wrapper {
    padding: 10px 25px;

    @media screen and (max-width: 1200px) {
      width: 100%;
      justify-content: center;
      display: flex;
      padding: 15px 5px;
    }
    @media screen and (max-width: 767px) {
      justify-content: space-between;
    }
  }

  .about,
  .contacts {
    padding: 10px 25px !important;
    @media screen and (max-width: 1200px) {
      padding: 10px 5px !important;
    }
    .heading {
      margin-bottom: 5px;
    }
  }

  .likari_in_ua {
    order: 3;

    @media screen and (max-width: 1200px) {
      width: 100%;
      text-align: center;
      padding: 15px 5px;
    }
  }

  .row {
    margin: 0;
  }
}

.sort_wrapper {
  &.desktop {
    display: block;
  }

  &.mobile {
    display: none;
    justify-content: center;
  }

  @media (max-width: 991px) {
    padding-top: 20px;
    background: #e8f1f7;

    &.mobile {
      display: flex;
    }

    &.desktop {
      display: none;
    }

    .sort_bar-title {
      display: none;
    }

    .sort_by-wrapper {
      margin: 0;
    }
  }

  @media (max-width: 480px) {
    width: 100%;

    .sort_bar {
      width: 100%;
      margin-right: 0 !important;
    }

    .sort_by-wrapper {
      width: 100%;
      min-width: auto !important;
      margin-left: 0 !important;
    }
  }
}

.top_specialists {
  @media (max-width: 991px) {
    .med_portal {
      display: flex;
    }
  }

  .specialities_box {
    justify-content: space-around;

    @media screen and (max-width: 1000px) {
      justify-content: initial;
    }

    @media (max-width: 510px) {
      .links_box {
        display: flex;
        justify-content: center;
        width: 50%;
      }
    }
  }
}

.top_specialist_box {
  display: flex;
  align-items: center;
  flex-direction: column;

  .links {
    display: flex;
    flex-direction: column;

    .name {
      color: #7f7f7f;
      font-size: 14px;

      &:hover {
        color: #1a7ef7;
        text-decoration: underline;
      }
    }
  }
}

.description {
  .doctors_item {
    .address {
      padding-left: 0;

      .address_text {
        padding-left: 0;
      }
    }
  }
}

#description {
  .hospital_data {
    border-left: 0;

    .directions_form {
      padding: 0px;
    }

    li {
      width: 100%;
    }
  }
}

.info_block {
  padding-bottom: 30px;

  .directions_form {
    select {
      width: -webkit-fill-available;
    }
  }
}

.info_block,
.doctors_block {
  .directions_form {
    display: flex;
    flex-direction: column;

    .block_title {
      font-size: 14px;
      line-height: 18px;
      color: #8e8e8e;
    }

    select {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #1d1b1b;
      margin-left: 0;
      margin-top: 7px;

      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
  }
}

.show_more {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #094fa3;
  display: flex;
  justify-content: flex-start;

  img {
    width: 15px;
    margin-left: 10px;
    transition: all 0.1s;

    &.rotate {
      transform: rotate(180deg);
    }
  }
}

.doctors_data,
.hospital_data {
  border-left: 1px solid #d0e5f0;
  padding: 15px 15px 15px 30px;
  min-width: 400px;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    padding: 20px 20px 15px 20px;
  }

  ul li {
    position: relative;
  }

  .question {
    position: absolute;
    right: 0;
  }

  .show_more {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #094fa3;
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
    text-decoration: none;

    img {
      width: 15px;
      margin-left: 10px;
      transition: all 0.1s;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  .star_wrapper {
    height: 50px;
    padding: 15px 25px;
  }

  .unit {
    color: #859ead;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
  }

  .btn,
  .medicament {
    width: 100%;
    display: flex;
    font-weight: bold;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin: auto 0 0;
  }

  .btn {
    background-color: #1a7ef7;
    border: 0;
  }

  .medicament {
    margin-bottom: 10px;
    line-height: 18px;
    color: #1a7ef7;
  }

  ul {
    height: auto !important;
    flex-wrap: wrap !important;
    padding: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    list-style: none;
    color: #696666;
    font-size: 16px;
    line-height: 21px;
    padding: 10px 0;

    span {
      font-size: 14px;
      max-width: 80%;
    }

    .number {
      display: flex;
      align-items: center;
      white-space: nowrap;
      padding: 5px;
      color: #1a7ef7;
      font-weight: bold;
    }
  }
}

.hospital_data {
  padding-top: 0;
}

.doctor_section {
  .doctors_item {
    display: flex;
    justify-content: center;

    .box {
      display: flex;
      justify-content: space-between;

      div {
        position: relative;
        font-size: 18px;
      }

      .tel {
        a,
        span {
          display: block;
          color: #094fa3;
        }

        &:before {
          content: url(../../assets/img/iconfinder_Resume_Phone_2316258.svg);
          vertical-align: middle;
          display: inline-block;
          margin-right: 15px;
          width: 25px;
        }
      }

      .time {
        color: #212529;
      }

      .address {
        padding: 15px 0;

        &:before {
          content: url(../../assets/img/iconfinder_location_1814106.svg);
          vertical-align: middle;
          display: inline-block;
          margin-right: 15px;
          width: 25px;
        }
      }

      .time:before {
        content: url(../../assets/img/iconfinder_10_171505.svg);
        vertical-align: middle;
        display: inline-block;
        margin-right: 15px;
        width: 25px;
      }
    }

    .hospital_data {
      width: 100%;
      max-width: 400px;

      @media (max-width: 991px) {
        max-width: 100%;
      }
    }

    .description_wrapper {
      flex-direction: column;
      padding-right: 20px;

      @media (max-width: 991px) {
        padding-right: 0;
      }

      .short_description {
        width: 100%;
      }

      .long_description {
        h3 {
          font-weight: bold;
          font-size: 20px;
          line-height: 26px;
          background: #f8f8f8;
          color: #1d1b1b;
          padding: 15px 0 15px 30px;
          margin-left: -25px;
          margin-bottom: 25px;

          &:first-of-type {
            margin-top: 25px;
          }
        }

        ul {
          list-style: none;

          li {
            font-size: 14px;
            line-height: 28px;
            color: #696666;

            &::before {
              content: '\2022';
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
              color: #094fa3;
            }
          }
        }

        p {
          font-size: 14px;
          line-height: 28px;
          color: #696666;

          b {
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            color: #333333;
          }
        }
      }
    }
  }
}

.search_page {
  .doctors_item,
  .hospitals_item {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .hospitals_item {
    .time {
      padding: 5px 0;
    }

    .short_description {
      padding-bottom: 20px;
    }
  }
}

.doctors_item,
.hospitals_item {
  border-radius: 5px;
  font-size: 14px;

  .address {
    color: #094fa3;
  }

  .time {
    cursor: default;
  }
}

.hospital_contacts {
  display: flex;

  a {
    color: #212529;
    padding: 3px 10px 0;
    border-right: 1px solid #e8f1f7;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      border-right: 0;
      padding-right: 0;
    }
  }
}

.search_title-container {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding-top: 100px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }

  .med_portal {
    display: flex;
  }

  .title {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }

  @media (max-width: 991px) {
    text-align: center;

    .med_portal {
      display: none;
    }
  }
}

.med_portal {
  display: none;
  flex-direction: column;
  justify-content: space-around;

  .link_title {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    color: #1c5caa;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
    }

    img {
      width: 13px;
      margin-left: 10px;
    }
  }

  span {
    font-size: 14px;
    line-height: 26px;
    color: #7f7f7f;
  }

  .link {
    font-size: 14px;
    color: #1c5caa;
  }
}

.back_btn {
  color: #094fa3;
}

.doctor_page {
  h3 {
    font-size: 24px;
  }

  .nav-tabs .nav-link {
    border: none;
  }
}

.container {
  @media screen and (max-width: 480px) {
    h3 {
      font-size: 20px;
    }
  }
}

.search_page {
  .nav-tabs .nav-link {
    text-transform: capitalize;

    &.active {
      color: #1c5caa;
      border-bottom: 3px solid #1a7ef7;
    }

    border: none;
  }
}

.input_search_results {
  max-height: 30vh;
  overflow-y: scroll;

  li {
    display: flex;
    justify-content: space-between;
  }

  .star {
    padding: 0 4px;

    img {
      width: 20px;
    }
  }
}

.working_hours_box .time_box {
  li {
    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }

  .more {
    .dots {
      font-size: 18px;
      line-height: 11px;
      font-weight: 700;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  padding: 55px 0;
  justify-content: center;
  text-align: center;

  span {
    font-size: 18px;
    font-weight: 600;
    color: #555;
  }

  img {
    width: 35px;
    margin-right: 10px;
  }
}

.short_description .doctors_box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: initial;
  }
}

.fetching {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 35px;
  }
}

.search_page {
  .search_result {
    .container {
      &.nav-tab {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .sort_bar {
    display: flex;
    align-items: center;

    .sort_bar-title {
      font-size: 14px;
      line-height: 18px;
      color: #8e8e8e;
      white-space: nowrap;
    }

    .sort_by-wrapper {
      margin-left: 10px;
      background: #ffffff;
      min-width: 380px;
      padding: 15px 20px;
      position: relative;

      .sort_by-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 14px;
          line-height: 21px;
          color: #1d1b1b;
          white-space: nowrap;
          margin-right: 10px;
        }

        img {
          width: 15px;
        }
      }

      .sort_by-menu {
        position: absolute;
        border-top: 1px solid #d8d8d8;
        z-index: 2;
        top: 3.15rem;
        left: 0;
        display: flex;
        flex-direction: column;
        padding: 15px 20px 0;
        box-shadow: 0 0.125rem 0.25rem rgba(184, 191, 211, 0.5);
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;

        div {
          padding: 10px 0;
          display: flex;
          align-items: baseline;

          img {
            width: 15px;
            margin-right: 5px;
            opacity: 0;
            visibility: hidden;

            &.active {
              visibility: visible;
              opacity: 1;
            }
          }

          span {
            font-size: 14px;
            line-height: 21px;
            color: #1d1b1b;

            &.active {
              color: #000;
            }
          }
        }

        &.open {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.stars {
  display: flex;
}

.star {
  padding: 0 7px;

  img {
    width: 22px;
    pointer-events: none;

    &:active {
      display: block;
    }
  }
}

.stars_wrapper {
  background: #f8f8f8;
  border-radius: 4px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 15px 25px;
  margin-top: 15px;
}

.rating {
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #d8d8d8;
    margin: 0px;

    span {
      font-weight: bold;
      font-size: 24px;
      line-height: 31px;
      color: #1d1b1b;
    }
  }
}

@media (max-width: 991px) {
  .hospital_section {
    .hospitals_item {
      margin-top: 80px;
      margin-left: -15px;
      margin-right: -15px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e8f1f7;
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;

      .text {
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .hospital_data {
      max-width: 100%;
      min-width: auto;
      border-top: 1px solid #d0e5f0;
      border-left: 0;
      border-top: 0 !important;
      padding: 0;
    }

    .header_box {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 991px) {
        .img_box {
          left: 50%;
          transform: translateX(-50%);
          border-radius: 50%;
          background: #fff;
          position: absolute;
          top: -60px;
          padding: 20px;
          width: 120px;
          height: 120px;
        }
      }

      @media (max-width: 768px) {
        .img_box {
          width: 100px;
          height: 100px;
        }
      }

      .rating_wrapper {
        .medicament {
          width: 100%;
          margin-top: 30px;
        }
      }
    }
  }

  .doctor_section {
    .doctors_item {
      margin-top: 80px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e8f1f7;
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      height: 100%;

      .doctors_calendar {
        padding: 0 15px;
      }

      .text {
        width: inherit;
        padding-top: 50px;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .img_box {
      left: 50%;
      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: -60px;
      padding: 20px;
      width: 120px;
      height: 120px;

      @media (max-width: 991px) {
        left: 5%;
        width: 100px;
        height: 100px;
      }
    }

    .hospital_data {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
      min-width: auto;
      border-top: 1px solid #d0e5f0;
      border-left: 0;
      padding-top: 20px;

      @media (max-width: 991px) {
        margin-top: 15px;
      }
    }

    @media (max-width: 768px) {
      .img_box {
        width: 100px;
        height: 100px;
      }
    }
  }
}

.hospital_section {
  .hospital_info {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .hospital_address,
    .time,
    .hospital_contacts {
      margin-bottom: 15px;
    }

    .hospital_address {
      display: inline-block;
    }

    &.container {
      padding: 5px 15px 5px 60px;
      background: #f8f8f8;
      border-radius: 4px;
      margin-bottom: 0;
    }
  }

  @media (max-width: 991px) {
    .name {
      font-size: 20px;
    }
  }

  .doctors_item {
    height: 100%;

    .short_description {
      height: 100%;
    }
  }

  .description .box_wrapper {
    display: flex;
    justify-content: space-between;

    #doctors {
      width: 100%;
    }

    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: initial;

      #doctors {
        max-width: 100%;
      }
    }
  }
}

.search_result #doctors,
.search_result #hospitals {
  @media (max-width: 991px) {
    .img_box {
      left: 5%;
    }
  }
}

.search_result #hospitals {
  .address_text {
    &:before {
      content: url(../img/iconfinder_location_1814106.svg);
      display: inline-block;
      margin-right: 0;
      width: 25px;
      vertical-align: middle;
    }
  }
}

.search_result #hospitals,
.search_result #doctors {
  .address {
    padding-left: 0;

    .address_text {
      padding-left: 0;
    }
  }

  @media (max-width: 768px) {
    .img_box {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 991px) {
    .img_box {
      border-radius: 50%;
      background: #fff;
      position: absolute;
      top: -60px;
      padding: 20px;
      width: 120px;
      height: 120px;
    }

    .hospitals_item,
    .doctors_item {
      margin-top: 80px;
    }

    .short_description,
    .hospitals_item {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      height: 100%;

      .text {
        padding-top: 50px;
        width: 100%;
      }
    }

    .hospital_data {
      max-width: 100%;
      min-width: auto;
      width: 100%;
      border-top: 1px solid #d0e5f0;
      border-left: 0;
    }
  }
}

.rating_box {
  position: absolute;
  right: 3%;
  border-bottom: 1px solid #e8e8e8;
  padding: 0 10px;

  .rating {
    margin-left: 15px;
  }

  .star {
    padding: 0 5px;

    img {
      width: 18px;
    }
  }
}

.phone_form {
  .error_text {
    padding-left: 15px;

    @media screen and (max-width: 768px) {
      padding-left: 0;
    }
  }
}

.appointment_field_container {
  margin-bottom: 1rem;
  align-items: center !important;

  .appointment_field_title {
    width: 40%;
    flex-direction: row !important;
    font-weight: 600;

    .require {
      color: red;
      margin-left: 3px !important;
    }
  }

  .appointment_field_wrapper {
    margin-left: auto;
    position: relative;
    flex-direction: column;
    max-width: 230px;

    .appointment_input-error {
      color: red;
      margin-bottom: 5px;
      font-size: 13px;

      @media (max-width: 480px) {
        position: initial;
      }
    }

    input {
      margin-bottom: 0 !important;
    }

    @media (max-width: 480px) {
      margin: 0;
      max-width: 100%;
    }
  }
}

.description_block {
  padding-left: 30px;

  h3 {
    padding-bottom: 15px;
  }

  @media screen and (max-width: 991px) {
    padding-left: 0;
  }

  .lines {
    p {
      margin: 0;
    }
  }

  .description_text {
    display: flex;

    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #1a7ef7;
      padding-left: 3px;
    }
  }
}

.alert {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  padding: 1.25rem 2.25rem !important;
  z-index: 1225;
  text-align: center;
}

.inline-alert {
  display: block;
  position: relative;
  z-index: 1;
}

.declaration_false {
  border: 0 !important;

  .error {
    padding: 10px 0;
    color: #ff0000 !important;
    font-weight: 500;
  }

  .appointment_field_container {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;

    div {
      width: 100%;
      max-width: inherit;

      input {
        width: 100%;
      }
    }
  }
  .appointment_field_container:nth-child(odd) {
    div {
      padding-right: 15px;
    }
  }
}

.personal_area {
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #1d1b1b;
    margin-bottom: 30px;
  }

  .sidebar {
    display: flex;
    width: 100%;
    max-width: 330px;
    background: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-direction: column;

    .name {
      display: flex;
      align-items: center;
      background: #1a7ef7;
      border: 2px solid #1a7ef7;
      border-radius: 4px;
      padding: 11px 12px;

      span {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #ffffff;
      }

      img {
        width: 23px;
      }
    }

    .tab {
      font-size: 14px;
      line-height: 22px;
      color: #696666;
      padding: 11px 12px;

      &:hover {
        background: #e8f1f7;
        color: #1c5caa;
      }
    }
  }
}

.logout_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 30px;
  background: #f7f7f7;
  line-height: 18px;
  color: #1c5caa;
  border-bottom: 1px solid #d7e9f2;

  .label {
    font-size: 14px;
    line-height: 18px;
    color: #696666 !important;
  }

  img {
    width: 20px;
  }
}

.appointments_list {
  background: transparent;

  &.col-md-6 {
    &:nth-child(odd) {
      padding-left: 0;
    }

    &:nth-child(even) {
      padding-right: 0;
    }

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .subtitle {
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #1d1b1b;
    margin-bottom: 30px;
    margin-top: 15px;
  }

  .appointment_placeholder {
    border: 2px dashed #d8d8d8;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 390px;
    margin-bottom: 20px;

    .text {
      font-size: 16px;
      line-height: 31px;
      color: #d8d8d8;
      padding-bottom: 15px;
    }
  }

  .appointment {
    background: #ffffff;
    margin-bottom: 20px;
    height: calc(100% - 20px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    .info_block {
      &:last-of-type {
        border: 0;
      }
    }

    .row {
      margin: 0;
      padding: 8px;
      padding-left: 30px;

      &:first-of-type {
        padding-top: 15px;
      }

      &:last-of-type {
        padding-bottom: 15px;
      }
    }

    @media screen and (max-width: 991px) {
      max-width: 100%;
    }

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      span {
        min-width: initial;
      }
    }

    &.past {
      h4 {
        color: #979797;
      }

      a {
        color: #979797;
      }

      span:last-of-type {
        color: #979797 !important;
      }
    }

    h4 {
      font-weight: bold;
      font-size: 14px;
      line-height: 23px;
      padding: 15px;
      padding-left: 30px;
      color: #000000;
      margin-bottom: 20px;

      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
    }

    .top_info {
      background: #f8f8f8;

      .row {
        font-size: 14px;
      }
    }

    .info_block {
      font-size: 14px;
      line-height: 21px;
      color: #696666;
      padding: 0;
      border-bottom: 1px solid #eaeaea;

      &:first-of-type {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-of-type {
        border-bottom: 0;
      }

      .row {
        display: flex;
        flex-direction: row;

        span {
          margin: 0;
          letter-spacing: 0.4px;
        }

        @media screen and (max-width: 991px) {
          span {
            font-size: 14px !important;
            width: 100% !important;
          }

          span:first-of-type {
            padding-bottom: 5px;
          }
        }

        span:first-of-type {
          font-size: 14px;
          line-height: 21px;
          color: #000;
          font-weight: 400;
        }

        span:last-of-type {
          font-size: 13px;
          line-height: 21px;
          color: #000000;

          &.date {
            color: #1a7ef7;
          }

          &.hospital {
            text-decoration-line: underline;
            color: #1a7ef7;
          }

          &.doctor {
            color: #1a7ef7;
          }
        }
      }
    }

    .btn_wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        width: 90%;
        border-radius: 4px;
        padding: 10px 0;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 13px;
      }
    }
  }
}

.office {
  padding-bottom: 50px;

  .office_title {
    padding: 30px 0;
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #1d1b1b;

    @media screen and (max-width: 767px) {
      font-size: 20px;
      padding: 20px 0;
    }
  }

  .tab-content {
    background-color: white;
    padding-top: 0;
    padding-bottom: 0;
  }

  table {
    td:first-child {
      padding-left: 30px;
    }

    td:last-child {
      padding-right: 30px;
    }

    th:first-child {
      padding-left: 30px;
    }

    th:last-child {
      padding-right: 30px;
    }
  }

  h3 {
    &.mb-2 {
      letter-spacing: 0.2px;
      font-size: 22px;
      font-weight: bold;
      padding-top: 50px;
    }
  }

  h4 {
    padding: 20px 10px 0px 30px;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .activeLink {
    background: #e8f1f7 !important;
    color: #1c5caa !important;
  }

  .table {
    font-size: 14px;
  }

  .office_link {
    white-space: normal;
    display: flex;
    align-items: center;
    color: #696666;
    font-size: 14px;
    padding: 12px 30px 12px 30px;
    background: #fff;

    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .office_placeholder {
    border: 1px dashed #d8d8d8;
    border-radius: 6px;
    height: 100%;
    text-align: center;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;

    span {
      font-size: 20px;
      line-height: 31px;
      color: #9a9a9a;
    }
  }

  .tab_menu {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0;

    @media (max-width: 767px) {
      max-width: inherit;
      box-shadow: none;
    }
  }

  .tab_menu-tabs {
    scroll-behavior: smooth;

    button {
      outline: none;
      border: 0;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      width: 100%;
      text-align: left;
    }

    .dropdown-item {
      padding: 0;
    }

    .dropdown-menu {
      width: 100%;
    }

    .dropdown-toggle::after {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media screen and (max-width: 767px) {
      display: flex;
      overflow-x: auto;
      padding: 9px 0;
      white-space: nowrap;

      .nav-link {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #d8d8d8;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }

        &.active {
          color: #1a7ef7;
        }
      }
    }
  }

  .patient {
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .dropdown_menu {
    position: absolute;
    right: 20px;

    i {
      border-color: #fff;
    }
  }

  img {
    margin-right: 5px;
  }
}

.dropdown_menu {
  i {
    margin: 0 20px;
    border: solid #777;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transition: all 0.1s;

    &.disabled {
      border-color: #cdcaca;
      cursor: default;
    }
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.text-muted {
  width: 40%;
}

.exam_table {
  background: transparent;

  .examinations_wrapper {
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 20px 0;

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.info_table {
  .full_name div {
    display: flex;
    align-items: center;

    @media screen and (max-width: 420px) {
      font-size: 14px;
    }
  }
}

.indicators_table,
.doctors_table,
.info_table {
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  td {
    border: 0 !important;
    font-size: 14px;
  }

  hr {
    margin-left: 30px;
    margin-right: 30px;
  }

  .full_name {
    font-size: 16px;
  }

  .position {
    margin-top: 10px;
  }
}

table th {
  font-weight: normal;
}

.login_btn {
  text-transform: inherit !important;
  border-width: 2px;
  margin-right: 25px;
  color: #1c5caa;
  font-size: 14px !important;
  display: flex !important;
  align-items: center;
  height: 42px;

  &.user {
    background-color: #007bff !important;
    border-color: #007bff !important;

    &:hover {
      background-color: #0069d9 !important;
      border-color: #0062cc;
    }
  }

  @media screen and (max-width: 1200px) {
    color: #fff;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px !important;
    padding: 6px 12px !important;
    white-space: nowrap;
  }

  img {
    margin-right: 5px;
  }
}

.exam_table {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.contacts_table,
.recipes_table,
.exam_table,
.vaccinations_table,
.indicators_table {
  margin-bottom: 0;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .table-responsive {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  table {
    margin-bottom: 0;
  }

  .no-data-placeholder {
    text-align: center;
    background: #e8e8e8;
  }

  td {
    font-weight: 500;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-right: 1px solid #eaeaea;

    &:last-of-type {
      border-right: 0 !important;
    }
  }

  th {
    color: #979797 !important;
    font-size: 14px;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-right: 1px solid #eaeaea;

    &:last-of-type {
      border-right: 1px solid #eaeaea;
    }
  }
}

.vaccinations_table {
  td {
    font-size: 14px !important;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8 !important;
}

.mgTop170 {
  @media screen and (max-width: 991px) {
    margin-top: 170px !important;
  }
}

.recipes_table {
  .recipes_header th {
    border: 0 !important;
    color: #696666 !important;
  }

  .header_rows {
    color: #979797 !important;
  }

  .doctors_name {
    padding: 15px 0;
  }
}

.contacts_table,
.recipes_table {
  .text-muted {
    width: initial;
  }

  td {
    font-size: 14px;
  }

  th {
    border-bottom-width: 0 !important;
  }
}

.patient_box {
  td {
    font-weight: bold;
  }

  .doctor {
    display: flex;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
    }

    .position {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      text-transform: uppercase;
      color: #1a7ef7;
    }
  }

  img {
    width: 75px;
    margin-right: 20px;
  }
}

.days_box {
  .datepicker_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .days_wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
      width: 100%;
    }
  }
}

.office_link a {
  display: flex;
  align-items: center;
}

.patient_button {
  text-align: left;
  font-size: 14px;
  white-space: pre-wrap;
  font-weight: bold;
  width: 100%;
  padding: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #007bff !important;
  background-color: #007bff !important;

  @media screen and (max-width: 767px) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media (max-width: 991px) {
    text-align: center;
  }

  &:hover {
    background-color: #0069d9 !important;
    border-color: #0062cc !important;
  }
}

.first_time_popup {
  .header {
    padding: 5px 16px;
    border: 0;

    button {
      padding: 0;
    }

    .title {
      font-weight: bold;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #000000;
    }
  }

  .body {
    padding: 5px 16px;
    font-size: 14px;
    line-height: 22px;
    color: #7f7f7f;

    p {
      margin: 0;

      &:first-of-type {
        padding-bottom: 10px;
      }

      &:last-of-type {
        padding-top: 10px;
      }
    }
    .city_placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #9b9b9b;
    }

    .city_select {
      button {
        width: 100%;
        text-align: left;

        &::after {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .footer {
    padding: 5px 12px;
    button {
      width: 100%;
    }
  }

  .body,
  .footer,
  .header {
    border: 0;
  }
}

.patient_select {
  .dropdown-menu {
    width: 100%;
    padding: 0;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .dropdown-item {
    white-space: pre-wrap;
    font-size: 15px;
    padding: 8px 25px;
  }

  button {
    text-align: left;
    font-size: 14px;
    white-space: pre-wrap;
    font-weight: bold;
    width: 100%;
    padding: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #007bff !important;
    background-color: #007bff !important;

    @media screen and (max-width: 767px) {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    @media (max-width: 991px) {
      text-align: center;
    }

    &:hover {
      background-color: #0069d9 !important;
      border-color: #0062cc !important;
    }
  }
}

.city_select {
  .dropdown-menu {
    width: 100%;
    padding: 0;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .dropdown-item {
    padding: 12px 15px;
    font-size: 15px !important;
    border-bottom: 1px solid #d7e9f2;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  button {
    font-size: 14px;
    color: rgb(28, 92, 170);
    border-width: 2px;
    box-shadow: none !important;

    &:hover,
    :focus {
      color: #fff;
      background-color: #007bff !important;
      border-color: #007bff !important;
    }
  }
}

main {
  background: #fff;
}

.home {
  background: #fafafa;

  section {
    background: #fafafa;
  }
}

.hospital_page {
  .info_block .hospital_data {
    padding-bottom: 40px;
  }

  .rating_wrapper {
    padding-top: 0;

    .stars_wrapper {
      margin-top: 0;
    }

    button {
      width: 100%;
    }
  }
}

.office_page {
  background: #edf4f8;
}

.flatpickr-calendar.static {
  @media screen and (max-width: 767px) {
    top: calc(100% + 60px) !important;
    left: -80px;
  }
}

.flatpickr-day {
  display: flex !important;
  align-items: center;
}
.doctor-substitution {
  background-color: #e3f6ff;
  color: #000;
}
.doctor-substitution span {
  color: #ff6e04;
  font-weight: 600
}
.doctor-substitution:hover {
  background-color: #3ca0d6;
}
.doctor-substitution:hover span{
  color: #fff;
}
.jobs-more-text {
  text-align: justify;
  margin-top: 10px;
  font-size: 12px;
  color: #525252;
}
.jobs-more-text i{
  color: #ff6e04;
}
.job-location-title {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  color: #3ca0d6;
}


.doctor-modal .modal-dialog {
  min-width: 80%;
  
}
.schedule-time-list__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: #094fa3;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .doctor-modal .modal-dialog {
    min-width: 96%;
  }
  .modal-content {
    padding: 30px 0;
  }
  .modal-content .schedule-wrapper {
    padding: 10px 0;
  }
  .modal-content .col-xl-6 {
    padding: 0;
  }
}